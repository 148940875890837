<template>
  <b-row class="match-height">
    <b-col lg="12">
      <b-card title="Payment Request">
        <b-form @keyup.enter.prevent="" @submit.prevent="submitNewPaymentRequest">
          <b-row>
            <b-col cols="12">
              <b-button
                type="button"
                variant="primary"
                class="mr-1"
                @click="previous()"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="16"
                  class="align-middle"
                />
                Back
              </b-button>
              <b-form-group
                label="Project"
                label-for="payment_request_project"
                label-cols-md="12"
              >
                <b-form-input
                  id="payment_request_project"
                  v-model="paymentRequestProject"
                  placeholder="Project"
                />
              </b-form-group>
              <b-form-group
                label="Currencies *"
                label-for="payment_request_currency_id"
                label-cols-md="12"
              >
                <v-select-new v-model="chosenCurrency" :options="currencies" />
              </b-form-group>
              <b-form-group
                label="Suppliers"
                label-for="payment_request_category"
                label-cols-md="12"
              >
                <v-select-new v-model="chosenSupplier" :options="suppliers" required/>
              </b-form-group>
              <b-form-group
                label="Bank"
                label-for="payment_request_bank"
                label-cols-md="12"
              >
                <b-form-input
                  id="payment_request_bank"
                  v-model="paymentRequestBank"
                  placeholder="Bank"
                />
              </b-form-group>
              <b-form-group
                label="Bank Account Number"
                label-for="payment_request_bank_number"
                label-cols-md="12"
              >
                <b-form-input
                  id="payment_request_bank_number"
                  v-model="paymentRequestBankAccountNumber"
                  placeholder="Bank Account Number"
                />
              </b-form-group>
              <b-form-group
                label="Bank Account Owner"
                label-for="payment_request_bank_owner"
                label-cols-md="12"
              >
                <b-form-input
                  id="payment_request_bank_owner"
                  v-model="paymentRequestBankAccountOwner"
                  placeholder="Bank Account Owner"
                />
              </b-form-group>
              <b-form-group
                label="Claimed To"
                label-for="payment_request_cliamed_to"
                label-cols-md="12"
              >
                <b-form-input
                  id="payment_request_cliamed_to"
                  v-model="PaymentRequestClaimedTo"
                  placeholder="Claimed To"
                />
              </b-form-group>
              <b-form-group
                label="Notes"
                label-for="payment_request_notes"
                label-cols-md="12"
              >
                <b-form-input
                  id="payment_request_notes"
                  v-model="paymentRequestNote"
                  placeholder="Notes"
                />
              </b-form-group>
              <b-form-group label="Details *" label-for="chosenItem">
</b-form-group>
              <b-row>
                <b-col cols="2" class="text-center font-weight-bold">
                  Division
                </b-col>
                <b-col cols="1" class="text-center font-weight-bold">
                  Cost Center
                </b-col>
                <b-col cols="2" class="text-center font-weight-bold">
                  Facture Number
                </b-col>
                <b-col cols="2" class="text-center font-weight-bold">
                  Description
                </b-col>
                <b-col cols="2" class="text-center font-weight-bold">
                  Due Date
                </b-col>
                <b-col cols="2" class="text-center font-weight-bold">
                  Nominal
                </b-col>
                <b-col cols="1" class="text-center font-weight-bold">
                  Actions
                </b-col>
              </b-row>
              <p />
              <div>
              <b-row v-for="chosen in chosens" :key="chosen.value">
                <br />
                <b-col cols="2 text-center">
                  <b-list-group>
                  <b-form-input
                      v-model="chosen.division"
                      type="text"
                      placeholder="Division"
                    />
                  </b-list-group>
                </b-col>
                <b-col cols="1 text-center">
                  <b-list-group>
                  <b-form-input
                      v-model="chosen.cost_center"
                      type="text"
                      placeholder="Cost Center"
                      readonly
                    />
                  </b-list-group>
                </b-col>
                <b-col cols="2 text-center">
                  <b-list-group>
                  <b-form-input
                      v-model="chosen.facture_number"
                      type="text"
                      placeholder="Facture Number"
                    />
                  </b-list-group>
                </b-col>
                <b-col cols="2 text-center">
                  <b-form-input
                      v-model="chosen.description"
                      type="text"
                      placeholder="Description"
                    />
                </b-col>
                <b-col cols="2 text-center">
                  <b-form-input
                      v-model="chosen.due_date"
                      type="date"
                      placeholder="Due Date"
                    />
                </b-col>
                <b-col cols="2 text-center">
                  <b-form-input
                      v-model="chosen.nominal"
                      type="number"
                      placeholder="Nominal"
                    />
                </b-col>
                <b-col cols="1 text-center">
                  <b-button
                    size="sm"
                    type="button"
                    variant="danger"
                    class="mr-1"
                    @click="removeRow(chosen.id)"
                  >
                    <feather-icon icon="TrashIcon" size="18" />
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <br />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-button variant="primary" block @click="addItem">
                    Add
                  </b-button>
                </b-col>
              </b-row>
              </div>
            </b-col>
            <!-- submit and reset -->
            <b-col offset-md="12">
              <br/>
              <b-button type="submit" variant="primary" class="mr-1"
                :disabled="submitButtonDisabled">
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import axios from "axios"
import moment from "moment"
import Ripple from "vue-ripple-directive"

import { ref } from "@vue/composition-api"

const types = ["Headoffice", "Cabang"]
const chosenType = "Cabang"
const VersionCheck = ""
const items = []
const itemsUnfiltered = []
const chosenItem = ref()
const chosens = []
const suppliers = []
const paymentRequestProject = ""
const paymentRequestCostCenter = ""
const paymentRequestQtyRequested = 0
const paymentRequestStatus = ""
const paymentRequestBankAccountNumber = ""
const paymentRequestBank = ""
const paymentRequestNote = ""
const paymentRequestCategory = ""
const paymentRequestCategoryAsset = ""
const paymentRequestNeedFor = ""
const paymentRequestBankAccountOwner = ""
const PaymentRequestClaimedTo = ""
const chosenBranch = ""
const chosenCurrency = ""
const chosenSupplier = ""
const userBranchCode = ""
const currencies = []
const chosenClass = ""
const categories = ["Assets", "Supplies", "FGS"]
const needs = ["Non Inventory", "Inventory", "Projects"]
const assetCategories = ["Machinery (MC)", "Furniture & Fixture", "Office Equipment", "Lab Equipment", "Vehicle (VH)", "Building", "Software", "Low Value Asset", "Land", "Field Equipment"]
const submitButtonDisabled = false

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userBranchCode,
      PaymentRequestClaimedTo,
      chosenSupplier,
      paymentRequestBankAccountOwner,
      chosenCurrency,
      paymentRequestNote,
      suppliers,
      assetCategories,
      needs,
      paymentRequestNeedFor,
      paymentRequestCategory,
      paymentRequestCategoryAsset,
      categories,
      chosenType,
      types,
      chosenBranch,
      currencies,
      chosenClass,
      VersionCheck,
      items,
      chosenItem,
      chosens,
      paymentRequestProject,
      paymentRequestCostCenter,
      paymentRequestQtyRequested,
      paymentRequestStatus,
      paymentRequestBankAccountNumber,
      paymentRequestBank,
      itemsUnfiltered,
      submitButtonDisabled,
    }
  },
  created() {
    window.setInterval(() => {
      this.removeReadOnly()
    }, 1000)
  },
  watch: {
    async paymentRequestCategory(newValue) {
      if (newValue === "Assets") {
        this.chosens = []
        this.items = this.itemsUnfiltered.filter(item => item.label.substring(0, 3) === 'ACL')
      } else if (newValue === "Supplies") {
        this.chosens = []
        this.items = this.itemsUnfiltered.filter(item => item.label.substring(0, 3) === 'WHS' || item.label.substring(0, 3) === 'SUP')
      } else if (newValue === "FGS") {
        this.chosens = []
        this.items = this.itemsUnfiltered.filter(item => item.label.substring(0, 3) === 'JSL' || item.label.substring(0, 3) === 'FGS')
      }
    },
  },
  mounted() {
    this.chosens = []
    this.getItems()
    this.getCurrencies()
    this.getSuppliers()

    const userData = this.$cookies.get('userData')
    const userBranch = userData.branch
    this.userBranchCode = userBranch.branch_code
  },
  methods: {
    addItem() {
      const generateId = () => {
    return Math.random().toString(36).substr(2, 9)
  }
      const temp = {
                division: "Coal/Batubara",
                facture_number: "",
                cost_center: this.userBranchCode,
                description: "",
                due_date: "",
                nominal: "",
                id: generateId,
              }
      this.chosens.push(temp)
    },
    checkMax(chosen) {
      const qty = parseInt(chosen.qty) || 0
      const max = parseInt(chosen.max) || 0
      if (qty > max) {
        chosen.qty = chosen.max
        this.$set(chosen, 'qty', chosen.max)
      }
    },
    removeReadOnly() {
      const searches = document.getElementsByClassName("vs__search")
      for (let i = 0; i < searches.length; i += 1) {
        searches[i].removeAttribute("readonly")
      }
    },
    getSuppliers() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_CONTACTS}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            let dataResp = []
            const itemsJoin = []
            dataResp = response.data.data
            dataResp.map(elem => {
              itemsJoin.push({
                label: `${elem.contact_name}`,
                value: elem.contact_id,
              })
            })
            this.suppliers = itemsJoin
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Currencies Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Get Currencies Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    getCurrencies() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_CURRENCIES}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            let dataResp = []
            const itemsJoin = []
            dataResp = response.data.data
            dataResp.map(elem => {
              itemsJoin.push({
                label: `${elem.currency_full_name} (${elem.currency_code})`,
                value: elem.currency_id,
              })
            })
            this.currencies = itemsJoin
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Currencies Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Get Currencies Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    filterByBranchCode(arr, code) {
      return arr.filter(item => item.branch_code === code)
    },
    getItems() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .post(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PR_PLAN}`,
          {},
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            const itemsJoin = []
            response.data.data.map(elem => {
              const temp = {
                label: `${elem.item.item_code} ${elem.item.item_name} (QTY : ${elem.payment_request_detail_item_qty_requested}) MR : ${elem.material_request ? elem.material_request.material_request_number : '-'}`,
                unit_name: `${elem.item.unit ? elem.item.unit.unit_name : '-'}`,
                value: elem.payment_request_detail_id,
                qty: elem.payment_request_detail_item_qty_requested,
                max: elem.payment_request_detail_item_qty_requested,
                avg: 0,
                note: "",
                mr_id: elem.payment_request_detail_mr_id,
              }
              console.log(temp)
              itemsJoin.push(temp)
            })
            this.itemsUnfiltered = itemsJoin
            this.items = itemsJoin
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Data Success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Data Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Get Settings Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    submitNewPaymentRequest() {
      this.submitButtonDisabled = true
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      if (this.chosens.length < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Please put at least one detail",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
        return
      }
      const body = {
        payment_request_project_name: this.paymentRequestProject,
        payment_request_currency_id: this.chosenCurrency,
        payment_request_supplier_id: this.chosenSupplier,
        payment_request_bank: this.paymentRequestBank,
        payment_request_bank_number: this.paymentRequestBankAccountNumber,
        payment_request_bank_owner: this.paymentRequestBankAccountOwner,
        payment_request_note: this.paymentRequestNote,
        payment_request_claimed_to: this.PaymentRequestClaimedTo,
        payment_request_details: JSON.stringify(this.chosens),
        time: moment(),
      }
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CREATE_PAYMENT_REQUEST}`
      console.log(url)
      axios
        .post(url, body, { headers })
        .then(response => {
          this.submitButtonDisabled = false
          if (response.data.success === true) {
            this.chosens = []
            this.paymentRequestProject = ""
            this.chosenCurrency = ""
            this.chosenSupplier = ""
            this.paymentRequestBank = ""
            this.paymentRequestBankAccountNumber = ""
            this.paymentRequestBankAccountOwner = ""
            this.paymentRequestNote = ""
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Payment Request Success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
            this.$router.replace({
              name: "apps-procurement-payment-request-list",
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Payment Request Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Payment Request Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    getOptionLabel(option) {
      return (option && option.label) || ""
    },
    putItem(val) {
      if (this.chosens.length > 0) {
        let same = false
        this.chosens.map(elem => {
          if (elem.value === val.value) {
            same = true
          }
        })
        if (same === false) {
          let item = null
          this.items.map(elem => {
            if (elem.value === val.value) {
              item = {
                unit_name: elem.unit_name,
                label: elem.label,
                value: elem.value,
                qty: elem.qty,
                max: elem.max,
                avg: elem.avg,
                mr_id: elem.mr_id,
                note: elem.note,
              }
            }
          })
          this.chosens.push(item)
        }
      } else {
        let item = null
        this.items.map(elem => {
          if (elem.value === val.value) {
            item = {
              unit_name: elem.unit_name,
              label: elem.label,
              value: elem.value,
              qty: elem.qty,
              avg: elem.avg,
              mr_id: elem.mr_id,
              note: elem.note,
            }
          }
        })

        this.chosens.push(item)
      }
    },
    applyItem(val) {
      console.log(val.data)
    },
    getOptionKey(option) {
      return (option && option.value) || ""
    },
    inputSearch(search) {
      console.log(search)
    },
    removeRow(val) {
      this.chosens = this.chosens.filter(obj => obj.id !== val)
    },
    dateFormat(value, format = "MM/DD/YYYY") {
      return moment(String(value)).format(format)
    },
    previous() {
      this.$router.push({ name: "apps-procurement-payment-request-list" })
    },
    capitalizeWords(str) {
    // Split the string into words using spaces as the separator
    const words = str.split(' ')
    // Capitalize the first letter of each word and join them back together
    const capitalizedWords = words.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1)
    })
    // Join the capitalized words into a single string with spaces
    return capitalizedWords.join(' ')
    },
  },
}
</script>
